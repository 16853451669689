import { useTranslation } from "~/composables/useTranslation";
import { useBrowserConfig } from "../useBrowserConfig";
import { useCourses } from "../useCourses";
import { useAccount } from "../useAccount";

export async function setupTransl() {
    const { fetchTransl, setLocale } = useTranslation();

    const { browserLanguage, browserTheme } = useBrowserConfig();
    const defaultLanguage = browserLanguage.value || browserTheme.value;

    await fetchTransl();
    await setLocale(defaultLanguage);
}

export async function setupAccount({ accountName, accountId, countryIso }: any) {
    const { fetchAccount } = useAccount();

    const hostName = (accountName || accountId || countryIso) ? '' : getBrowserHost();

    await fetchAccount({
        _id: accountId,
        accountName,
        hostName,
        countryIso
    })
}

export async function setupCourses({ accountId }: any) {
    const { fetchCourses } = useCourses();

    fetchCourses({
        accountId: accountId
    });
}