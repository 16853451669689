export function getBrowserLanguage() {
    const nav: any = navigator;
    const [language] = (nav?.language || nav?.userLanguage)?.match(/^[\w]{2}/) || [];

    return language;
}

export function getBrowserTheme() {
    const darkThemeMq = window?.matchMedia("(prefers-color-scheme: dark)");
    return darkThemeMq?.matches ? 'dark' : 'light';
}

export function getBrowserHost() {
    return window.location.hostname;
}