import { useAccount } from "../useAccount";
import { useGeoloc } from "../useGeoloc";
import { activeLocale } from "../useTranslation"
import { setupAccount, setupCourses, setupTransl } from "./setup.utils";
import { watchAccount } from "./setup.watch";

export function useAppSetup() {

    async function setupSettings() {
        await setupTransl();
        console.log('language -> ', activeLocale.value);

        const { fetchGeo, geoloc } = useGeoloc();
        await fetchGeo();
        console.log('geo -> ', toRaw(geoloc));

        const accountId = ''; //'61e9933702ba69001c19279a';
        const accountName = ''; //'61e9933702ba69001c19279a';
        const countryIso = geoloc.countryIso;
        await setupAccount({ accountName, accountId, countryIso });

        const { activeAccount } = useAccount();
        console.log('account -> ', activeAccount.value?.account_name);

        await setupCourses({ accountId: activeAccount.value?._id });
        console.log('fetch courses ...');

        watchAccount();
    }

    return {
        setupSettings
    }
}