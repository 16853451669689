export function useBrowserConfig() {

    const browserLanguage = ref('');
    const browserTheme = ref('');

    const onBrowserThemeChange = () => {
        const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
        darkThemeMq.onchange = (e) => {
            browserTheme.value = e.matches ? 'dark' : 'light'
        }
    };

    setTimeout(() => onBrowserThemeChange(), 0)

    // setup browser language and theme
    browserLanguage.value = getBrowserLanguage();
    browserTheme.value = getBrowserTheme();

    return {
        browserLanguage,
        browserTheme
    }
}